.lambert-mobile {
  align-items: center;
  background-color: #e3fcff;
  border: 1px none;
  display: flex;
  flex-direction: column;
  gap: 80px;
  justify-content: center;
  min-width: 375px;
  padding: 80px 20px 40px;
  position: relative;
}

.lambert-mobile .frame {
  height: 154px;
  left: 0;
  position: absolute;
  top: 0;
  width: 375px;
}

.lambert-mobile .img {
  height: 350px;
  left: 0;
  position: absolute;
  top: 154px;
  width: 375px;
}

.lambert-mobile .frame-2 {
  height: 350px;
  left: 0;
  position: absolute;
  top: 504px;
  width: 375px;
}

.lambert-mobile .frame-3 {
  height: 350px;
  left: 0;
  position: absolute;
  top: 854px;
  width: 375px;
}

.lambert-mobile .frame-4 {
  height: 350px;
  left: 0;
  position: absolute;
  top: 1204px;
  width: 375px;
}

.lambert-mobile .frame-5 {
  height: 350px;
  left: 0;
  position: absolute;
  top: 1554px;
  width: 375px;
}

.lambert-mobile .frame-6 {
  height: 350px;
  left: 0;
  position: absolute;
  top: 1904px;
  width: 375px;
}

.lambert-mobile .frame-7 {
  height: 350px;
  left: 0;
  position: absolute;
  top: 2254px;
  width: 375px;
}

.lambert-mobile .frame-8 {
  height: 350px;
  left: 0;
  position: absolute;
  top: 2604px;
  width: 375px;
}

.lambert-mobile .frame-9 {
  height: 350px;
  left: 0;
  position: absolute;
  top: 2954px;
  width: 375px;
}

.lambert-mobile .frame-10 {
  height: 350px;
  left: 0;
  position: absolute;
  top: 3304px;
  width: 375px;
}

.lambert-mobile .frame-11 {
  height: 350px;
  left: 0;
  position: absolute;
  top: 3654px;
  width: 375px;
}

.lambert-mobile .frame-12 {
  height: 350px;
  left: 0;
  position: absolute;
  top: 4004px;
  width: 375px;
}

.lambert-mobile .frame-13 {
  height: 350px;
  left: 0;
  position: absolute;
  top: 4354px;
  width: 375px;
}

.lambert-mobile .frame-14 {
  height: 350px;
  left: 0;
  position: absolute;
  top: 4704px;
  width: 375px;
}

.lambert-mobile .frame-15 {
  height: 350px;
  left: 0;
  position: absolute;
  top: 5054px;
  width: 375px;
}

.lambert-mobile .frame-16 {
  height: 74px;
  left: 0;
  position: absolute;
  top: 5404px;
  width: 375px;
}

.lambert-mobile .div {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 60px;
  position: relative;
  width: fit-content;
}

.lambert-mobile .frame-17 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: relative;
  width: fit-content;
}

.lambert-mobile .overlap-wrapper {
  height: 227px;
  min-width: 170.59px;
  position: relative;
}

.lambert-mobile .overlap {
  height: 226px;
  position: relative;
  width: 171px;
}

.lambert-mobile .text-wrapper {
  color: #000000;
  font-family: "DynaPuff", Helvetica;
  font-size: 32px;
  font-weight: 400;
  height: 39px;
  left: 14px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 187px;
  width: 142px;
}

.lambert-mobile .overlap-group-wrapper {
  height: 188px;
  left: 0;
  position: absolute;
  top: 0;
  width: 171px;
}

.lambert-mobile .overlap-group {
  height: 188px;
  position: relative;
}

.lambert-mobile .ellipse {
  background-color: #5aebff;
  border-radius: 84.28px;
  height: 169px;
  left: 1px;
  position: absolute;
  top: 12px;
  width: 169px;
}

.lambert-mobile .vector {
  height: 54px;
  left: 110px;
  position: absolute;
  top: 4px;
  width: 59px;
}

.lambert-mobile .vector-2 {
  height: 54px;
  left: 2px;
  position: absolute;
  top: 4px;
  width: 59px;
}

.lambert-mobile .vector-3 {
  height: 13px;
  left: 10px;
  position: absolute;
  top: 44px;
  width: 23px;
}

.lambert-mobile .vector-4 {
  height: 16px;
  left: 7px;
  position: absolute;
  top: 15px;
  width: 15px;
}

.lambert-mobile .group {
  height: 167px;
  left: 24px;
  position: absolute;
  top: 10px;
  width: 116px;
}

.lambert-mobile .vector-5 {
  height: 19px;
  left: 70px;
  position: absolute;
  top: 99px;
  width: 31px;
}

.lambert-mobile .group-2 {
  height: 60px;
  left: 0;
  position: absolute;
  top: 0;
  width: 62px;
}

.lambert-mobile .group-3 {
  height: 8px;
  left: 40px;
  position: absolute;
  top: 35px;
  width: 14px;
}

.lambert-mobile .group-4 {
  height: 60px;
  left: 108px;
  position: absolute;
  top: 0;
  width: 62px;
}

.lambert-mobile .vector-6 {
  height: 9px;
  left: 67px;
  position: absolute;
  top: 72px;
  width: 9px;
}

.lambert-mobile .vector-7 {
  height: 9px;
  left: 90px;
  position: absolute;
  top: 72px;
  width: 9px;
}

.lambert-mobile .group-5 {
  height: 35px;
  left: 40px;
  position: absolute;
  top: 152px;
  width: 84px;
}

.lambert-mobile .vector-8 {
  height: 14px;
  left: 94px;
  position: absolute;
  top: 13px;
  width: 11px;
}

.lambert-mobile .vector-9 {
  height: 12px;
  left: 32px;
  position: absolute;
  top: 126px;
  width: 15px;
}

.lambert-mobile .vector-10 {
  height: 9px;
  left: 25px;
  position: absolute;
  top: 37px;
  width: 7px;
}

.lambert-mobile .vector-11 {
  height: 10px;
  left: 55px;
  position: absolute;
  top: 23px;
  width: 9px;
}

.lambert-mobile .vector-12 {
  height: 10px;
  left: 106px;
  position: absolute;
  top: 29px;
  width: 7px;
}

.lambert-mobile .vector-13 {
  height: 6px;
  left: 35px;
  position: absolute;
  top: 9px;
  width: 7px;
}

.lambert-mobile .vector-14 {
  height: 7px;
  left: 87px;
  position: absolute;
  top: 16px;
  width: 5px;
}

.lambert-mobile .vector-15 {
  height: 6px;
  left: 67px;
  position: absolute;
  top: 15px;
  width: 5px;
}

.lambert-mobile .vector-16 {
  height: 5px;
  left: 62px;
  position: absolute;
  top: 34px;
  width: 4px;
}

.lambert-mobile .vector-17 {
  height: 5px;
  left: 106px;
  position: absolute;
  top: 23px;
  width: 4px;
}

.lambert-mobile .vector-18 {
  height: 7px;
  left: 125px;
  position: absolute;
  top: 115px;
  width: 6px;
}

.lambert-mobile .vector-19 {
  height: 9px;
  left: 53px;
  position: absolute;
  top: 38px;
  width: 7px;
}

.lambert-mobile .vector-20 {
  height: 4px;
  left: 125px;
  position: absolute;
  top: 108px;
  width: 3px;
}

.lambert-mobile .vector-21 {
  height: 7px;
  left: 127px;
  position: absolute;
  top: 95px;
  width: 5px;
}

.lambert-mobile .vector-22 {
  height: 4px;
  left: 65px;
  position: absolute;
  top: 21px;
  width: 3px;
}

.lambert-mobile .vector-23 {
  height: 3px;
  left: 118px;
  position: absolute;
  top: 120px;
  width: 4px;
}

.lambert-mobile .vector-24 {
  height: 5px;
  left: 78px;
  position: absolute;
  top: 18px;
  width: 7px;
}

.lambert-mobile .vector-25 {
  height: 17px;
  left: 131px;
  position: absolute;
  top: 98px;
  width: 8px;
}

.lambert-mobile .vector-26 {
  height: 18px;
  left: 26px;
  position: absolute;
  top: 108px;
  width: 8px;
}

.lambert-mobile .vector-27 {
  height: 3px;
  left: 42px;
  position: absolute;
  top: 93px;
  width: 4px;
}

.lambert-mobile .vector-28 {
  height: 4px;
  left: 122px;
  position: absolute;
  top: 87px;
  width: 3px;
}

.lambert-mobile .vector-29 {
  height: 5px;
  left: 44px;
  position: absolute;
  top: 82px;
  width: 4px;
}

.lambert-mobile .vector-30 {
  height: 4px;
  left: 90px;
  position: absolute;
  top: 25px;
  width: 3px;
}

.lambert-mobile .vector-31 {
  height: 6px;
  left: 31px;
  position: absolute;
  top: 103px;
  width: 6px;
}

.lambert-mobile .vector-32 {
  height: 5px;
  left: 38px;
  position: absolute;
  top: 111px;
  width: 4px;
}

.lambert-mobile .vector-33 {
  height: 10px;
  left: 120px;
  position: absolute;
  top: 124px;
  width: 9px;
}

.lambert-mobile .vector-34 {
  height: 7px;
  left: 28px;
  position: absolute;
  top: 90px;
  width: 7px;
}

.lambert-mobile .vector-35 {
  height: 44px;
  left: 129px;
  position: absolute;
  top: 14px;
  width: 39px;
}

.lambert-mobile .group-6 {
  height: 8px;
  left: 117px;
  position: absolute;
  top: 35px;
  width: 14px;
}

.lambert-mobile .meet-lambert-your {
  color: #115f78;
  font-family: "DynaPuff", Helvetica;
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  width: 335px;
}

.lambert-mobile .span {
  color: #115f78;
  font-family: "DynaPuff", Helvetica;
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0;
}

.lambert-mobile .lambert-is-an-office {
  color: #000000;
  font-family: "Open Sans", Helvetica;
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 26.4px;
  position: relative;
  text-align: center;
  width: 335px;
}

.lambert-mobile .link-add-to-slack {
  height: 40px;
  min-width: 139px;
  position: relative;
}

.lambert-mobile .frame-18 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  position: relative;
  width: fit-content;
}

.lambert-mobile .frame-19 {
  height: 253px;
  min-width: 335px;
  position: relative;
}

.lambert-mobile .frame-20 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  position: relative;
  width: 335px;
}

.lambert-mobile .text-wrapper-2 {
  color: #115f78;
  font-family: "DynaPuff", Helvetica;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.lambert-mobile .frame-21 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.lambert-mobile .track-debts-between {
  align-self: stretch;
  color: #000000;
  font-family: "Open Sans", Helvetica;
  font-size: 19px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20.9px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
}

.lambert-mobile .text-wrapper-3 {
  color: #000000;
  font-family: "Open Sans", Helvetica;
  font-size: 19px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20.9px;
}

.lambert-mobile .div-2 {
  align-self: stretch;
  color: #000000;
  font-family: "Open Sans", Helvetica;
  font-size: 19px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20.9px;
  position: relative;
  white-space: nowrap;
}

.lambert-mobile .search-and-deliver {
  align-self: stretch;
  color: #000000;
  font-family: "Open Sans", Helvetica;
  font-size: 19px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20.9px;
  position: relative;
}

.lambert-mobile .frame-22 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  position: relative;
}

.lambert-mobile .frame-23 {
  height: 195px;
  min-width: 335px;
  position: relative;
}

.lambert-mobile .frame-24 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  position: relative;
}

.lambert-mobile .p {
  align-self: stretch;
  color: #000000;
  font-family: "Open Sans", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  text-align: center;
}

.lambert-mobile .image {
  height: 337.57px;
  margin-bottom: -6px;
  margin-left: -2px;
  margin-right: -6px;
  min-width: 343px;
  object-fit: cover;
  position: relative;
}

.lambert-mobile .export-this {
  height: 246px;
  min-width: 246px;
  position: relative;
}

.lambert-mobile .pay-for-your-swears {
  align-self: stretch;
  color: #000000;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  text-align: center;
}

.lambert-mobile .frame-25 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 162px;
  justify-content: center;
  position: relative;
  width: 335px;
}

.lambert-mobile .div-wrapper {
  height: 70px;
  min-width: 335px;
  position: relative;
}

.lambert-mobile .overlap-group-2 {
  background-image: url(../static/img-mobile/new-1.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 78px;
  position: relative;
  top: -2px;
  width: 335px;
}

.lambert-mobile .rectangle {
  height: 17px;
  left: 196px;
  position: absolute;
  top: 35px;
  width: 114px;
}

.lambert-mobile .text-wrapper-4 {
  color: #1266a4;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 15px;
  font-weight: 400;
  height: 19px;
  left: 199px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 32px;
  width: 106px;
}

.lambert-mobile .stats-no-swears {
  height: 46px;
  min-width: 335px;
  object-fit: cover;
  position: relative;
}

.lambert-mobile .frame-26 {
  height: 257px;
  min-width: 231px;
  position: relative;
}

.lambert-mobile .heading-settle {
  align-self: stretch;
  color: #115f78;
  font-family: "DynaPuff", Helvetica;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
}

.lambert-mobile .frame-27 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  padding: 1px 0px;
  position: relative;
  width: 335px;
}

.lambert-mobile .image-2 {
  height: 132.1px;
  margin-left: -2px;
  margin-right: -6px;
  margin-top: -2px;
  min-width: 343px;
  object-fit: cover;
  position: relative;
}

.lambert-mobile .frame-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: fit-content;
}

.lambert-mobile .frame-28 {
  height: 129.45px;
  min-width: 335px;
  position: relative;
}

.lambert-mobile .frame-29 {
  height: 129px;
  width: 335px;
}

.lambert-mobile .overlap-group-3 {
  background-image: url(../static/img-mobile/roll3-1.png);
  background-size: 100% 100%;
  height: 137px;
  left: -2px;
  position: relative;
  top: -2px;
  width: 343px;
}

.lambert-mobile .rectangle-2 {
  height: 13px;
  left: 236px;
  position: absolute;
  top: 107px;
  width: 82px;
}

.lambert-mobile .text-wrapper-5 {
  color: #1266a4;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 10px;
  font-weight: 400;
  height: 15px;
  left: 238px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 104px;
  width: 83px;
}

.lambert-mobile .frame-30 {
  height: 196px;
  min-width: 89px;
  position: relative;
}

.lambert-mobile .heading-create {
  color: #115f78;
  font-family: "DynaPuff", Helvetica;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  width: fit-content;
}

.lambert-mobile .frame-31 {
  background-image: url(../static/img-mobile/answer-1.png);
  background-size: 100% 100%;
  height: 130px;
  min-width: 335px;
  position: relative;
}

.lambert-mobile .overlap-group-4 {
  height: 12px;
  left: 40px;
  position: relative;
  top: 11px;
  width: 58px;
}

.lambert-mobile .rectangle-3 {
  background-color: #fae9b4;
  height: 11px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 58px;
}

.lambert-mobile .text-wrapper-6 {
  color: #1266a4;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 8px;
  font-weight: 400;
  height: 10px;
  left: 1px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

.lambert-mobile .frame-32 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 60px;
  padding: 40px 0px;
  position: relative;
  width: 335px;
}

.lambert-mobile .text-wrapper-7 {
  align-self: stretch;
  color: #115f78;
  font-family: "DynaPuff", Helvetica;
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.lambert-mobile .frame-33 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-left: -20px;
  margin-right: -20px;
  position: relative;
  width: fit-content;
}

.lambert-mobile .frame-34 {
  align-items: flex-start;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 60px;
  padding: 40px 0px;
  position: relative;
  width: 375px;
}

.lambert-mobile .frame-35 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
  position: relative;
}

.lambert-mobile .frame-36 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: relative;
}

.lambert-mobile .text-wrapper-8 {
  color: #115f78;
  font-family: "DynaPuff", Helvetica;
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.lambert-mobile .frame-37 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 40px;
  position: relative;
}

.lambert-mobile .text-wrapper-9 {
  align-self: stretch;
  color: #115f78;
  font-family: "DynaPuff", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
}

.lambert-mobile .it-s-really-simple {
  align-self: stretch;
  color: #000000;
  font-family: "Open Sans", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
}

.lambert-mobile .p-2 {
  align-self: stretch;
  color: transparent;
  font-family: "Open Sans", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
}

.lambert-mobile .text-wrapper-10 {
  color: #000000;
}

.lambert-mobile .text-wrapper-11 {
  color: #115f78;
}

.lambert-mobile .frame-38 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  position: relative;
}

.lambert-mobile .yes-for-now-at-least {
  align-self: stretch;
  color: #000000;
  font-family: "Open Sans", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  white-space: nowrap;
}

.lambert-mobile .text-wrapper-12 {
  color: #000000;
  font-family: "Open Sans", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
}

.lambert-mobile .frame-39 {
  align-self: stretch;
  height: 182px;
  min-width: 335px;
  position: relative;
}

.lambert-mobile .frame-40 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  position: relative;
  width: 375px;
}

.lambert-mobile .made-with-by {
  color: transparent;
  font-family: "Open Sans", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.lambert-mobile .div-align-center {
  align-items: flex-start;
  display: flex;
  position: relative;
  width: fit-content;
}

.lambert-mobile .link-support {
  color: #115f78;
  font-family: "Open Sans", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.lambert-mobile .product-hunt {
  width: 250px;
  height: 54px;
  position:fixed;
  top: 20px;
  right: 20px;
  z-index: 100;
}