.lambert {
  align-items: center;
  background-color: #e3fcff;
  border: 1px none;
  display: flex;
  flex-direction: column;
  gap: 120px;
  padding: 100px 50px;
  position: relative;
  overflow: hidden;
  min-width: 320px;
}

.lambert .frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0;
  height: auto;
}

.lambert .div {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 60px;
  position: relative;
  width: fit-content;
}

.lambert .frame-2 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: relative;
  width: fit-content;
}

.lambert .group {
  height: 334px;
  min-width: 253px;
  position: relative;
}

.lambert .frame-santa-1 {
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  width: 100%;
  gap: 30px;
}
.lambert .text-wrapper {
  color: #000000;
  font-family: "DynaPuff", Helvetica;
  font-size: 48px;
  font-weight: 400;
  height: 58px;
  left: 21px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 276px;
}

.lambert .overlap-group-wrapper {
  height: 276px;
  left: 0;
  position: absolute;
  top: 0;
  width: 251px;
}

.lambert .overlap-group {
  height: 276px;
  position: relative;
}

.lambert .ellipse {
  background-color: #5aebff;
  border-radius: 124px;
  height: 248px;
  left: 1px;
  position: absolute;
  top: 18px;
  width: 248px;
}

.lambert .vector {
  height: 80px;
  left: 161px;
  position: absolute;
  top: 6px;
  width: 87px;
}

.lambert .img {
  height: 80px;
  left: 3px;
  position: absolute;
  top: 6px;
  width: 87px;
}

.lambert .vector-2 {
  height: 20px;
  left: 15px;
  position: absolute;
  top: 65px;
  width: 34px;
}

.lambert .vector-3 {
  height: 23px;
  left: 11px;
  position: absolute;
  top: 23px;
  width: 22px;
}

.lambert .group-2 {
  height: 245px;
  left: 35px;
  position: absolute;
  top: 14px;
  width: 170px;
}

.lambert .vector-4 {
  height: 28px;
  left: 103px;
  position: absolute;
  top: 145px;
  width: 46px;
}

.lambert .group-3 {
  height: 89px;
  left: 0;
  position: absolute;
  top: 0;
  width: 92px;
}

.lambert .group-4 {
  height: 12px;
  left: 59px;
  position: absolute;
  top: 52px;
  width: 20px;
}

.lambert .group-5 {
  height: 89px;
  left: 159px;
  position: absolute;
  top: 0;
  width: 92px;
}

.lambert .vector-5 {
  height: 14px;
  left: 99px;
  position: absolute;
  top: 106px;
  width: 14px;
}

.lambert .vector-6 {
  height: 14px;
  left: 133px;
  position: absolute;
  top: 106px;
  width: 14px;
}

.lambert .group-6 {
  height: 52px;
  left: 59px;
  position: absolute;
  top: 224px;
  width: 124px;
}

.lambert .vector-7 {
  height: 21px;
  left: 139px;
  position: absolute;
  top: 19px;
  width: 17px;
}

.lambert .vector-8 {
  height: 17px;
  left: 48px;
  position: absolute;
  top: 186px;
  width: 22px;
}

.lambert .vector-9 {
  height: 13px;
  left: 37px;
  position: absolute;
  top: 55px;
  width: 11px;
}

.lambert .vector-10 {
  height: 14px;
  left: 81px;
  position: absolute;
  top: 34px;
  width: 14px;
}

.lambert .vector-11 {
  height: 14px;
  left: 156px;
  position: absolute;
  top: 43px;
  width: 10px;
}

.lambert .vector-12 {
  height: 9px;
  left: 51px;
  position: absolute;
  top: 14px;
  width: 11px;
}

.lambert .vector-13 {
  height: 10px;
  left: 128px;
  position: absolute;
  top: 24px;
  width: 7px;
}

.lambert .vector-14 {
  height: 8px;
  left: 99px;
  position: absolute;
  top: 23px;
  width: 7px;
}

.lambert .vector-15 {
  height: 7px;
  left: 90px;
  position: absolute;
  top: 50px;
  width: 6px;
}

.lambert .vector-16 {
  height: 7px;
  left: 156px;
  position: absolute;
  top: 34px;
  width: 6px;
}

.lambert .vector-17 {
  height: 10px;
  left: 184px;
  position: absolute;
  top: 169px;
  width: 10px;
}

.lambert .vector-18 {
  height: 14px;
  left: 78px;
  position: absolute;
  top: 56px;
  width: 11px;
}

.lambert .vector-19 {
  height: 6px;
  left: 184px;
  position: absolute;
  top: 159px;
  width: 4px;
}

.lambert .vector-20 {
  height: 11px;
  left: 186px;
  position: absolute;
  top: 140px;
  width: 8px;
}

.lambert .vector-21 {
  height: 6px;
  left: 96px;
  position: absolute;
  top: 31px;
  width: 4px;
}

.lambert .vector-22 {
  height: 4px;
  left: 174px;
  position: absolute;
  top: 176px;
  width: 6px;
}

.lambert .vector-23 {
  height: 8px;
  left: 115px;
  position: absolute;
  top: 26px;
  width: 10px;
}

.lambert .vector-24 {
  height: 25px;
  left: 192px;
  position: absolute;
  top: 144px;
  width: 11px;
}

.lambert .vector-25 {
  height: 26px;
  left: 38px;
  position: absolute;
  top: 159px;
  width: 12px;
}

.lambert .vector-26 {
  height: 4px;
  left: 62px;
  position: absolute;
  top: 137px;
  width: 5px;
}

.lambert .vector-27 {
  height: 5px;
  left: 179px;
  position: absolute;
  top: 128px;
  width: 5px;
}

.lambert .vector-28 {
  height: 7px;
  left: 65px;
  position: absolute;
  top: 120px;
  width: 7px;
}

.lambert .vector-29 {
  height: 5px;
  left: 133px;
  position: absolute;
  top: 37px;
  width: 5px;
}

.lambert .vector-30 {
  height: 9px;
  left: 46px;
  position: absolute;
  top: 152px;
  width: 9px;
}

.lambert .vector-31 {
  height: 7px;
  left: 56px;
  position: absolute;
  top: 164px;
  width: 6px;
}

.lambert .vector-32 {
  height: 15px;
  left: 177px;
  position: absolute;
  top: 183px;
  width: 13px;
}

.lambert .vector-33 {
  height: 11px;
  left: 42px;
  position: absolute;
  top: 132px;
  width: 10px;
}

.lambert .vector-34 {
  height: 65px;
  left: 190px;
  position: absolute;
  top: 21px;
  width: 58px;
}

.lambert .group-7 {
  height: 12px;
  left: 172px;
  position: absolute;
  top: 52px;
  width: 21px;
}

.lambert .meet-lambert-your {
  color: #115f78;
  font-family: "DynaPuff", Helvetica;
  font-size: 38px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
  text-align: center;
}

.lambert .span {
  color: #115f78;
  font-family: "DynaPuff", Helvetica;
  font-size: 38px;
  font-weight: 400;
  letter-spacing: 0;
  text-align: center;
}

.lambert .lambert-is-an-office {
  color: #000000;
  font-family: "Open Sans", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  text-align: center;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.lambert .policy-text {
  color: #000000;
  font-family: "Open Sans", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  text-align: justify;
  width: fit-content;
}

.lambert .link-add-to-slack {
  height: 40px;
  min-width: 139px;
  position: relative;
}

.lambert .frame-3 {
  align-items: center;
  display: flex;
  gap: 30px;
  position: relative;
  width: fit-content;
  text-align: center;
  flex-direction: row-reverse;
}

.lambert .frame-swear-1 {
  align-items: center;
  display: flex;
  gap: 30px;
  position: relative;
  width: 960px;
  text-align: center;
  flex-direction: row;
}

.lambert .frame-multiple-features {
  align-items: flex-start;
  display: flex;
  gap: 30px;
  position: relative;
  width: fit-content;
  flex-direction: column;
}

.lambert .frame-santa-2 {
  align-items: flex-start;
  display: flex;
  gap: 30px;
  position: relative;
  width: fit-content;
  flex-direction: column;
}

.lambert .frame-swear-2 {
  align-items: flex-start;
  display: flex;
  gap: 30px;
  position: relative;
  width: fit-content;
  flex-direction: column;
}

.lambert .text-wrapper-2 {
  color: #115f78;
  font-family: "DynaPuff", Helvetica;
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
.lambert .bank-header {
  color: #115f78;
  font-family: "DynaPuff", Helvetica;
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  /* width: fit-content; */
}

.lambert .frame-5 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: fit-content;
}

.lambert .track-debts-between {
  color: #000000;
  font-family: "Open Sans", Helvetica;
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24.2px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.lambert .text-wrapper-3 {
  color: #000000;
  font-family: "Open Sans", Helvetica;
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24.2px;
  text-align: left;
}

.lambert .span-wrapper {
  color: #000000;
  font-family: "Open Sans", Helvetica;
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24.2px;
  position: relative;
  /* white-space: nowrap; */
  width: fit-content;
}

.lambert .frame-6 {
  height: 347px;
  min-width: 138px;
  position: relative;
}

.lambert .frame-7 {
  align-items: center;
  display: flex;
  gap: 30px;
  position: relative;
  width: fit-content;
  text-align: center;
  flex-direction: row;
}

.overlap-group-swear-jar {
  float: left;
}

.lambert .open-debt-png {
  background-color: #e3fcff;
  height: 400px;
  /* min-width: 432px; */
  position: relative;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  margin-top: 13px;
}

.lambert .secret-santa {
  /* height: 400px; */
  /* min-width: 432px; */
  position: relative;

  border-radius: 7px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.lambert .frame-8 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: relative;
  width: 408px;
}

.lambert .p {
  align-self: stretch;
  color: #000000;
  font-family: "Open Sans", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
}

.lambert .poll-text {
  align-self: stretch;
  color: #000000;
  font-family: "Open Sans", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
}

.lambert .bank-text {
  align-self: stretch;
  color: #000000;
  font-family: "Open Sans", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
}

.lambert .frame-9 {
  height: 195px;
  min-width: 134px;
  position: relative;
}

.lambert .pay-for-your-swears {
  color: #000000;
  font-family: "Open Sans", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  /* white-space: nowrap; */
  text-align: left;
}

.lambert .overlap-wrapper {
  height: 312px;
  min-width: 408px;
  position: relative;
}

.lambert .overlap {
  height: 312px;
  left: 103px;
  position: relative;
  width: 268px;
}

.lambert .overlap-group-2 {
  height: 306px;
  left: 0px;
  position: absolute;
  top: 6px;
  width: 173px;
}

.lambert .vector-35 {
  height: 25px;
  left: 91px;
  position: absolute;
  top: 2px;
  width: 26px;
}

.lambert .rectangle {
  background-color: #ffffff;
  height: 45px;
  left: 47px;
  position: absolute;
  top: 115px;
  width: 54px;
}

.lambert .vector-36 {
  height: 40px;
  left: 0;
  position: absolute;
  top: 267px;
  width: 153px;
}

.lambert .vector-37 {
  height: 11px;
  left: 83px;
  position: absolute;
  top: 237px;
  width: 29px;
}

.lambert .vector-38 {
  height: 32px;
  left: 30px;
  position: absolute;
  top: 2px;
  width: 34px;
}

.lambert .vector-39 {
  height: 8px;
  left: 35px;
  position: absolute;
  top: 26px;
  width: 13px;
}

.lambert .vector-40 {
  height: 26px;
  left: 103px;
  position: absolute;
  top: 8px;
  width: 23px;
}

.lambert .vector-41 {
  height: 9px;
  left: 33px;
  position: absolute;
  top: 9px;
  width: 9px;
}

.lambert .vector-42 {
  height: 11px;
  left: 45px;
  position: absolute;
  top: 237px;
  width: 29px;
}

.lambert .vector-43 {
  height: 73px;
  left: 44px;
  position: absolute;
  top: 129px;
  width: 66px;
}

.lambert .vector-44 {
  height: 97px;
  left: 48px;
  position: absolute;
  top: 180px;
  width: 23px;
}

.lambert .group-8 {
  height: 208px;
  left: 28px;
  position: absolute;
  top: 6px;
  width: 145px;
}

.lambert .vector-45 {
  height: 106px;
  left: 85px;
  position: absolute;
  top: 173px;
  width: 24px;
}

.lambert .vector-46 {
  height: 11px;
  left: 69px;
  position: absolute;
  top: 58px;
  width: 18px;
}

.lambert .group-9 {
  height: 35px;
  left: 28px;
  position: absolute;
  top: 0;
  width: 36px;
}

.lambert .vector-47 {
  height: 5px;
  left: 52px;
  position: absolute;
  top: 21px;
  width: 8px;
}

.lambert .group-10 {
  height: 35px;
  left: 91px;
  position: absolute;
  top: 0;
  width: 36px;
}

.lambert .vector-48 {
  height: 5px;
  left: 95px;
  position: absolute;
  top: 21px;
  width: 9px;
}

.lambert .vector-49 {
  height: 48px;
  left: 25px;
  position: absolute;
  top: 243px;
  width: 50px;
}

.lambert .vector-50 {
  height: 17px;
  left: 24px;
  position: absolute;
  top: 278px;
  width: 51px;
}

.lambert .vector-51 {
  height: 48px;
  left: 82px;
  position: absolute;
  top: 243px;
  width: 50px;
}

.lambert .vector-52 {
  height: 17px;
  left: 82px;
  position: absolute;
  top: 278px;
  width: 51px;
}

.lambert .vector-53 {
  height: 6px;
  left: 67px;
  position: absolute;
  top: 42px;
  width: 5px;
}

.lambert .vector-54 {
  height: 6px;
  left: 81px;
  position: absolute;
  top: 42px;
  width: 5px;
}

.lambert .vector-55 {
  height: 8px;
  left: 83px;
  position: absolute;
  top: 7px;
  width: 7px;
}

.lambert .vector-56 {
  height: 7px;
  left: 47px;
  position: absolute;
  top: 74px;
  width: 8px;
}

.lambert .vector-57 {
  height: 5px;
  left: 140px;
  position: absolute;
  top: 115px;
  width: 6px;
}

.lambert .vector-58 {
  height: 6px;
  left: 53px;
  position: absolute;
  top: 228px;
  width: 6px;
}

.lambert .vector-59 {
  height: 5px;
  left: 98px;
  position: absolute;
  top: 239px;
  width: 6px;
}

.lambert .vector-60 {
  height: 17px;
  left: 41px;
  position: absolute;
  top: 106px;
  width: 11px;
}

.lambert .vector-61 {
  height: 17px;
  left: 96px;
  position: absolute;
  top: 207px;
  width: 11px;
}

.lambert .vector-62 {
  height: 10px;
  left: 129px;
  position: absolute;
  top: 133px;
  width: 13px;
}

.lambert .vector-63 {
  height: 5px;
  left: 43px;
  position: absolute;
  top: 22px;
  width: 4px;
}

.lambert .vector-64 {
  height: 6px;
  left: 60px;
  position: absolute;
  top: 13px;
  width: 5px;
}

.lambert .vector-65 {
  height: 6px;
  left: 90px;
  position: absolute;
  top: 17px;
  width: 4px;
}

.lambert .vector-66 {
  height: 4px;
  left: 49px;
  position: absolute;
  top: 5px;
  width: 4px;
}

.lambert .vector-67 {
  height: 4px;
  left: 79px;
  position: absolute;
  top: 9px;
  width: 3px;
}

.lambert .vector-68 {
  height: 3px;
  left: 67px;
  position: absolute;
  top: 9px;
  width: 3px;
}

.lambert .vector-69 {
  height: 3px;
  left: 64px;
  position: absolute;
  top: 20px;
  width: 2px;
}

.lambert .vector-70 {
  height: 3px;
  left: 90px;
  position: absolute;
  top: 13px;
  width: 2px;
}

.lambert .vector-71 {
  height: 4px;
  left: 101px;
  position: absolute;
  top: 67px;
  width: 4px;
}

.lambert .vector-72 {
  height: 4px;
  left: 38px;
  position: absolute;
  top: 128px;
  width: 3px;
}

.lambert .vector-73 {
  height: 5px;
  left: 59px;
  position: absolute;
  top: 22px;
  width: 4px;
}

.lambert .vector-74 {
  height: 2px;
  left: 101px;
  position: absolute;
  top: 63px;
  width: 2px;
}

.lambert .vector-75 {
  height: 2px;
  left: 102px;
  position: absolute;
  top: 120px;
  width: 2px;
}

.lambert .vector-76 {
  height: 2px;
  left: 46px;
  position: absolute;
  top: 183px;
  width: 2px;
}

.lambert .vector-77 {
  height: 4px;
  left: 102px;
  position: absolute;
  top: 55px;
  width: 3px;
}

.lambert .vector-78 {
  height: 4px;
  left: 110px;
  position: absolute;
  top: 128px;
  width: 3px;
}

.lambert .vector-79 {
  height: 4px;
  left: 96px;
  position: absolute;
  top: 204px;
  width: 3px;
}

.lambert .vector-80 {
  height: 4px;
  left: 45px;
  position: absolute;
  top: 178px;
  width: 3px;
}

.lambert .vector-81 {
  height: 4px;
  left: 35px;
  position: absolute;
  top: 149px;
  width: 3px;
}

.lambert .vector-82 {
  height: 4px;
  left: 64px;
  position: absolute;
  top: 232px;
  width: 3px;
}

.lambert .vector-83 {
  height: 4px;
  left: 103px;
  position: absolute;
  top: 115px;
  width: 3px;
}

.lambert .vector-84 {
  height: 4px;
  left: 48px;
  position: absolute;
  top: 208px;
  width: 3px;
}

.lambert .vector-85 {
  height: 2px;
  left: 66px;
  position: absolute;
  top: 12px;
  width: 2px;
}

.lambert .vector-86 {
  height: 2px;
  left: 97px;
  position: absolute;
  top: 70px;
  width: 2px;
}

.lambert .vector-87 {
  height: 2px;
  left: 125px;
  position: absolute;
  top: 135px;
  width: 2px;
}

.lambert .vector-88 {
  height: 2px;
  left: 143px;
  position: absolute;
  top: 121px;
  width: 2px;
}

.lambert .vector-89 {
  height: 2px;
  left: 140px;
  position: absolute;
  top: 128px;
  width: 2px;
}

.lambert .vector-90 {
  height: 2px;
  left: 94px;
  position: absolute;
  top: 209px;
  width: 2px;
}

.lambert .vector-91 {
  height: 2px;
  left: 102px;
  position: absolute;
  top: 235px;
  width: 2px;
}

.lambert .vector-92 {
  height: 3px;
  left: 74px;
  position: absolute;
  top: 10px;
  width: 4px;
}

.lambert .vector-93 {
  height: 10px;
  left: 104px;
  position: absolute;
  top: 57px;
  width: 4px;
}

.lambert .vector-94 {
  height: 10px;
  left: 145px;
  position: absolute;
  top: 105px;
  width: 4px;
}

.lambert .vector-95 {
  height: 10px;
  left: 43px;
  position: absolute;
  top: 63px;
  width: 5px;
}

.lambert .vector-96 {
  height: 15px;
  left: 86px;
  position: absolute;
  top: 229px;
  width: 7px;
}

.lambert .vector-97 {
  height: 14px;
  left: 28px;
  position: absolute;
  top: 167px;
  width: 6px;
}

.lambert .vector-98 {
  height: 14px;
  left: 50px;
  position: absolute;
  top: 215px;
  width: 6px;
}

.lambert .vector-99 {
  height: 2px;
  left: 53px;
  position: absolute;
  top: 54px;
  width: 2px;
}

.lambert .vector-100 {
  height: 2px;
  left: 39px;
  position: absolute;
  top: 138px;
  width: 2px;
}

.lambert .vector-101 {
  height: 2px;
  left: 56px;
  position: absolute;
  top: 207px;
  width: 2px;
}

.lambert .vector-102 {
  height: 2px;
  left: 99px;
  position: absolute;
  top: 51px;
  width: 2px;
}

.lambert .vector-103 {
  height: 3px;
  left: 54px;
  position: absolute;
  top: 48px;
  width: 3px;
}

.lambert .vector-104 {
  height: 2px;
  left: 81px;
  position: absolute;
  top: 15px;
  width: 2px;
}

.lambert .vector-105 {
  height: 3px;
  left: 47px;
  position: absolute;
  top: 60px;
  width: 3px;
}

.lambert .vector-106 {
  height: 3px;
  left: 123px;
  position: absolute;
  top: 140px;
  width: 3px;
}

.lambert .vector-107 {
  height: 3px;
  left: 91px;
  position: absolute;
  top: 213px;
  width: 3px;
}

.lambert .vector-108 {
  height: 3px;
  left: 140px;
  position: absolute;
  top: 124px;
  width: 3px;
}

.lambert .vector-109 {
  height: 4px;
  left: 33px;
  position: absolute;
  top: 165px;
  width: 4px;
}

.lambert .vector-110 {
  height: 4px;
  left: 57px;
  position: absolute;
  top: 215px;
  width: 4px;
}

.lambert .vector-111 {
  height: 2px;
  left: 33px;
  position: absolute;
  top: 153px;
  width: 2px;
}

.lambert .vector-112 {
  height: 2px;
  left: 57px;
  position: absolute;
  top: 221px;
  width: 2px;
}

.lambert .vector-113 {
  height: 2px;
  left: 62px;
  position: absolute;
  top: 234px;
  width: 2px;
}

.lambert .vector-114 {
  height: 3px;
  left: 50px;
  position: absolute;
  top: 65px;
  width: 3px;
}

.lambert .vector-115 {
  height: 3px;
  left: 37px;
  position: absolute;
  top: 134px;
  width: 3px;
}

.lambert .vector-116 {
  height: 7px;
  left: 32px;
  position: absolute;
  top: 155px;
  width: 6px;
}

.lambert .vector-117 {
  height: 7px;
  left: 61px;
  position: absolute;
  top: 237px;
  width: 6px;
}

.lambert .vector-118 {
  height: 7px;
  left: 60px;
  position: absolute;
  top: 206px;
  width: 6px;
}

.lambert .vector-119 {
  height: 3px;
  left: 41px;
  position: absolute;
  top: 124px;
  width: 3px;
}

.lambert .vector-120 {
  height: 6px;
  left: 98px;
  position: absolute;
  top: 72px;
  width: 5px;
}

.lambert .vector-121 {
  height: 6px;
  left: 32px;
  position: absolute;
  top: 137px;
  width: 5px;
}

.lambert .vector-122 {
  height: 6px;
  left: 95px;
  position: absolute;
  top: 197px;
  width: 6px;
}

.lambert .vector-123 {
  height: 6px;
  left: 41px;
  position: absolute;
  top: 207px;
  width: 6px;
}

.lambert .vector-124 {
  height: 10px;
  left: 105px;
  position: absolute;
  top: 117px;
  width: 8px;
}

.lambert .vector-125 {
  height: 4px;
  left: 45px;
  position: absolute;
  top: 52px;
  width: 4px;
}

.lambert .frame-10 {
  height: 87px;
  left: 123px;
  position: absolute;
  top: 7px;
  width: 47px;
}

.lambert .frame-11 {
  height: 69px;
  left: 0;
  position: absolute;
  top: 0;
  width: 114px;
}

.lambert .frame-swear-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 420px;
  position: relative;
  width: 522px;
}

.lambert .div-wrapper {
  height: 70px;
  min-width: 336px;
  position: relative;
}

.lambert .overlap-group-3 {
  background-position: 50% 50%;
  background-size: cover;
  height: 78px;
  position: relative;
  top: -2px;
  width: 342px;
}

.lambert .rectangle-2 {
  background-color: #fae9b4;
  height: 18px;
  left: 196px;
  position: absolute;
  top: 35px;
  width: 115px;
}

.lambert .text-wrapper-4 {
  color: #1266a4;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 15px;
  font-weight: 400;
  height: 19px;
  left: 200px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 32px;
  width: 108px;
}

.lambert .stats-no-swears {
  position: relative;
  border-radius: 4px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
}

.lambert .frame-poll-1 {
  align-items: center;
  display: flex;
  gap: 30px;
  position: relative;
  width: 960px;
}

.lambert .frame-poll-2 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  padding: 1px 0px;
  position: relative;
}

.lambert .frame-poll-examples {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.lambert .frame-15 {
  align-self: stretch;
  height: 153px;
  min-width: 413px;
  position: relative;
}

.lambert .overlap-group-4 {
  height: 400px;
  left: -2px;
  position: relative;
  top: -2px;
  width: 421px;
}

.lambert .poll {
  height: 168px;
  width: 546px;
  position: relative;
  border-radius: 4px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
}
.lambert .roll {
  position: relative;
  border-radius: 4px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
}

.lambert .poll-2 {
  /* height: 160px; */
  /* left: 212px; */
  position: relative;
  top: 0;
  width: 209px;
}

.lambert .frame-16 {
  align-self: stretch;
  height: 148px;
  min-width: 413px;
  position: relative;
}

.lambert .overlap-2 {
  /* background-image: url(../static/img/roll3-1.png); */
  background-size: 100% 100%;
  height: 155px;
  left: -2px;
  position: relative;
  top: -2px;
  width: 421px;
}

.lambert .rectangle-3 {
  background-color: #fae9b4;
  height: 14px;
  left: 290px;
  position: absolute;
  top: 122px;
  width: 101px;
}

.lambert .text-wrapper-5 {
  color: #1266a4;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 13px;
  font-weight: 400;
  height: 16px;
  left: 293px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 118px;
}

.lambert .frame-poll-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: relative;
  width: 517px;
  margin-left: 110px;
}

.lambert .heading-settle {
  align-self: stretch;
  color: #115f78;
  font-family: "DynaPuff", Helvetica;
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  /* white-space: nowrap; */
}

.lambert .frame-18 {
  height: 242px;
  min-width: 224px;
  position: relative;
}

.lambert .overlap-3 {
  height: 242px;
  position: relative;
  width: 224px;
}

.lambert .overlap-group-5 {
  height: 242px;
  left: 20px;
  position: absolute;
  top: 0;
  width: 224px;
}

.lambert .group-11 {
  height: 27px;
  left: 0;
  position: absolute;
  top: 215px;
  width: 110px;
}

.lambert .group-12 {
  height: 74px;
  left: 26px;
  position: absolute;
  top: 158px;
  width: 30px;
}

.lambert .group-13 {
  height: 76px;
  left: 59px;
  position: absolute;
  top: 154px;
  width: 30px;
}

.lambert .group-14 {
  height: 4px;
  left: 42px;
  position: absolute;
  top: 188px;
  width: 4px;
}

.lambert .group-15 {
  height: 2px;
  left: 35px;
  position: absolute;
  top: 217px;
  width: 2px;
}

.lambert .group-16 {
  height: 4px;
  left: 70px;
  position: absolute;
  top: 197px;
  width: 4px;
}

.lambert .group-17 {
  height: 11px;
  left: 68px;
  position: absolute;
  top: 174px;
  width: 7px;
}

.lambert .group-18 {
  height: 3px;
  left: 68px;
  position: absolute;
  top: 172px;
  width: 2px;
}

.lambert .group-19 {
  height: 3px;
  left: 49px;
  position: absolute;
  top: 190px;
  width: 2px;
}

.lambert .group-20 {
  height: 1px;
  left: 66px;
  position: absolute;
  top: 176px;
  width: 1px;
}

.lambert .group-21 {
  height: 1px;
  left: 73px;
  position: absolute;
  top: 195px;
  width: 1px;
}

.lambert .group-22 {
  height: 10px;
  left: 62px;
  position: absolute;
  top: 188px;
  width: 4px;
}

.lambert .group-23 {
  height: 10px;
  left: 48px;
  position: absolute;
  top: 209px;
  width: 5px;
}

.lambert .group-24 {
  height: 4px;
  left: 60px;
  position: absolute;
  top: 225px;
  width: 12px;
}

.lambert .group-25 {
  height: 10px;
  left: 38px;
  position: absolute;
  top: 178px;
  width: 4px;
}

.lambert .group-26 {
  height: 1px;
  left: 44px;
  position: absolute;
  top: 174px;
  width: 1px;
}

.lambert .group-27 {
  height: 2px;
  left: 65px;
  position: absolute;
  top: 178px;
  width: 2px;
}

.lambert .group-28 {
  height: 3px;
  left: 44px;
  position: absolute;
  top: 179px;
  width: 3px;
}

.lambert .group-29 {
  height: 1px;
  left: 45px;
  position: absolute;
  top: 183px;
  width: 1px;
}

.lambert .group-30 {
  height: 1px;
  left: 48px;
  position: absolute;
  top: 192px;
  width: 1px;
}

.lambert .group-31 {
  height: 5px;
  left: 47px;
  position: absolute;
  top: 194px;
  width: 4px;
}

.lambert .group-32 {
  height: 3px;
  left: 64px;
  position: absolute;
  top: 211px;
  width: 2px;
}

.lambert .group-33 {
  height: 1px;
  left: 62px;
  position: absolute;
  top: 215px;
  width: 1px;
}

.lambert .group-34 {
  height: 4px;
  left: 71px;
  position: absolute;
  top: 207px;
  width: 3px;
}

.lambert .frame-14 {
  width: 100%;
  height: auto;
  display:grid;
  gap: 30px;
}

.lambert .group-35 {
  height: 5px;
  left: 46px;
  position: absolute;
  top: 173px;
  width: 4px;
}

.lambert .group-36 {
  height: 6px;
  left: 28px;
  position: absolute;
  top: 217px;
  width: 6px;
}

.lambert .group-37 {
  height: 7px;
  left: 76px;
  position: absolute;
  top: 221px;
  width: 6px;
}

.lambert .group-38 {
  height: 7px;
  left: 72px;
  position: absolute;
  top: 222px;
  width: 4px;
}

.lambert .group-39 {
  height: 7px;
  left: 32px;
  position: absolute;
  top: 222px;
  width: 6px;
}

.lambert .group-40 {
  height: 7px;
  left: 39px;
  position: absolute;
  top: 224px;
  width: 4px;
}

.lambert .group-41 {
  height: 15px;
  left: 102px;
  position: absolute;
  top: 51px;
  width: 19px;
}

.lambert .group-42 {
  height: 7px;
  left: 91px;
  position: absolute;
  top: 63px;
  width: 9px;
}

.lambert .group-43 {
  height: 64px;
  left: 112px;
  position: absolute;
  top: 0;
  width: 112px;
}

.lambert .group-44 {
  height: 11px;
  left: 160px;
  position: absolute;
  top: 26px;
  width: 14px;
}

.lambert .group-45 {
  height: 139px;
  left: 16px;
  position: absolute;
  top: 35px;
  width: 90px;
}

.lambert .group-46 {
  height: 33px;
  left: 19px;
  position: absolute;
  top: 131px;
  width: 21px;
}

.lambert .text-wrapper-6 {
  color: #000000;
  font-family: "Open Sans", Helvetica;
  font-size: 26px;
  font-weight: 400;
  height: 24px;
  left: 127px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 19px;
  white-space: nowrap;
}

.lambert .text-wrapper-7 {
  color: #000000;
  font-family: "Open Sans", Helvetica;
  font-size: 26px;
  font-weight: 400;
  height: 24px;
  left: 183px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 18px;
  white-space: nowrap;
}

.lambert .group-47 {
  height: 81px;
  left: 117px;
  position: absolute;
  top: 91px;
  width: 84px;
}

.lambert .santa-svg {
  height: 205px;
  min-width: 408px;
  position: relative;
}

.lambert .frame-20 {
  /* background-image: url(../static/img/answer-1.png); */
  background-size: 100% 100%;
  /* min-height: 269px; */
  /* min-width: 778px; */
  position: relative;
  margin-top: -70px;
  /* border-radius: 4px; */
  /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); */
}

.lambert .overlap-group-6 {
  height: 17px;
  left: 63px;
  position: relative;
  top: 18px;
  width: 90px;
}

.lambert .rectangle-4 {
  background-color: #fae9b4;
  height: 14px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 90px;
}

.lambert .text-wrapper-8 {
  color: #1266a4;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 12px;
  font-weight: 400;
  height: 15px;
  left: 3px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

.lambert .text-wrapper-9 {
  color: #115f78;
  font-family: "DynaPuff", Helvetica;
  font-size: 38px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.lambert .bank-header-text {
  /* background-color: #fae9b4; */
  text-align: left;
  display: grid;
  gap: 30px;
}

.lambert .frame-21 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 60px;
  position: relative;
  width: fit-content;
}

.lambert .section {
  align-items: flex-end;
  background-color: #ffffff;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  padding: 30px 60px;
  position: relative;
  width: 960px;
}

.lambert .frame-22 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: relative;
}

.lambert .frame-23 {
  align-items: flex-start;
  display: flex;
  gap: 60px;
  position: relative;
  width: fit-content;
}

.lambert .frame-24 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 60px;
  position: relative;
  width: 390px;
}

.lambert .text-wrapper-10 {
  align-self: stretch;
  color: #115f78;
  font-family: "DynaPuff", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
}

.lambert .p-2 {
  align-self: stretch;
  color: transparent;
  font-family: "Open Sans", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
}

.lambert .text-wrapper-11 {
  color: #000000;
}

.lambert .text-wrapper-12 {
  color: #115f78;
}
.lambert .text-wrapper-mail-top {
  color: #115f78;
}

.lambert .frame-25 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: relative;
  width: 390px;
}

.lambert .frame-26 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 390px;
}

.lambert .frame-27 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  position: relative;
  width: 390px;
}

.lambert .text-wrapper-13 {
  color: #115f78;
  font-family: "DynaPuff", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.lambert .yes-for-now-at-least {
  color: #000000;
  font-family: "Open Sans", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  /* white-space: nowrap; */
  width: fit-content;
}


.lambert .swear-header-text {
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-bottom: 2px;
  display: grid;
  gap: 30px;
}

.lambert .text-wrapper-14 {
  color: #000000;
  font-family: "Open Sans", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  display: flex;
}


.lambert .frame-poll-header-text {
  width: 100%;
  display: grid;
  gap: 30px;
}

.lambert .frame-28 {
  height: 182px;
  min-width: 390px;
  position: relative;
}

.lambert .frame-29 {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 960px;
}

.lambert .made-with-by {
  color: transparent;
  font-family: "Open Sans", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  text-align: left;
  white-space: nowrap;
  width: fit-content;
}

.lambert .div-align-center {
  align-items: flex-start;
  display: flex;
  position: relative;
  width: fit-content;
}

.lambert .link-support {
  color: #115f78;
  font-family: "Open Sans", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: prewrap;
  width: fit-content;
}

.lambert .santa-header-text{
  display: grid;
  gap: 30px;
}

.lambert .product-hunt {
  width: 250px;
  height: 54px;
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 100;
}

@media (max-width: 1250px) {
  .lambert .secret-santa {
    width: 100%;
    height: auto;
  }
  .lambert .frame-20 {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 1000px) {
  .lambert .frame-3 {
    flex-direction: column;
  }
  .lambert .text-wrapper-10 {
    width: 100%;
    height: auto;
  }
  .lambert .frame-21 {
    width: 100%;
    height: auto;
  }
  .lambert .frame-24 {
    width: 100%;
    height: auto;
  }
  .lambert .frame-25 {
    width: 100%;
    height: auto;
  }
  .lambert .frame-26 {
    width: 100%;
    height: auto;
  }
  .lambert .frame-27 {
    width: 100%;
    height: auto;
  }
  .lambert .frame-29 {
    width: 100%;
    height: auto;
    flex-direction: column;
    align-items: center;
  }
  .lambert .frame-14 {
    width: 100%;
    height: auto;
    display:grid;
    gap: 30px;
  }
  .lambert .frame-28 {
    width: 100%;
    height: auto;
    min-width: 0px;
    max-width: 400px;
  }
  .lambert .div-align-center {
    width: 100%;
    height: auto;
  }
  .lambert .text-wrapper-10 {
    width: 100%;
    height: auto;
  }
  .lambert .text-wrapper-13 {
    width: 100%;
    height: auto;
  }
  .lambert .text-wrapper-12 {
    width: 100%;
    height: auto;
    display: flex;
    word-break: break-all;
    text-align: center;
  }
  .lambert .text-wrapper-14 {
    width: 100%;
    height: auto;
  }
  .lambert .yes-for-now-at-least {
    width: 100%;
    height: auto;
  }
  .lambert .made-with-by {
    width: 100%;
    height: auto;
    flex-direction: column-reverse;
  }
  .lambert .section {
    width: 100%;
    height: auto;
  }
  .lambert .frame-20 {
    width: 100%;
    height: auto;
    padding-top: 60px;
  }
  .lambert .text-wrapper-9 {
    width: 100%;
    height: auto;
    text-align: center;
  }
  .lambert .frame-23 {
    width: 100%;
    height: auto;
    flex-direction: column-reverse;
  }

  .lambert .santa-svg {
    height: auto;
    max-height: 205px;
    width: 10%;
    position: relative;
    /* margin-left: -66%; */
  }
  .lambert .overlap-group-4 {
    height: auto;
    width: 100%;
  }
  .lambert .swear-header-text {
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-bottom: 2px;
    gap: 30px;
  }
  .lambert .heading-settle {
    width: 100%;
    /* font-size: 20px; */
    text-align: center;
  }
  .lambert .frame-multiple-features {
    text-align: center;
    width: 100%;
  }
  .lambert .frame-santa-2 {
    flex-direction: column-reverse;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .lambert .frame-swear-2 {
    text-align: center;
    flex-direction: column-reverse;
    align-items: center;
    align-self: center;
    margin-bottom: 0px;
    width: 100%;
  }
  .lambert .frame-poll-2 {
    flex-direction: column-reverse;
  }
  .lambert .frame-7 {
    flex-direction: column-reverse;
    width: 100%;
  }
  .lambert .frame-8 {
    flex-direction: column-reverse;
    align-items: center;
    align-self: center;
  }
  .lambert .frame-swear-1 {
    flex-direction: column;
    width: 100%;
  }
  .lambert .frame-poll-1 {
    flex-direction: column-reverse;
    margin-top: -64px;
    width: 100%;
  }
  .lambert .frame-santa-1 {
    flex-direction: column;
    text-align: center;
    /* align-items: center; */
    justify-content: center;
    margin-top: -64px;
    width: 100%;
    gap: 30px;
  }
  .lambert .bank-header-text {
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .lambert .text-wrapper-2 {
    text-align: center;
    align-items: center;
    justify-content: center;
    width: auto;
    margin: 16px;
  }
  .lambert .p {
    width: 100%;
    height: auto;
  }
  .lambert .poll-text {
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 16px;
    width: 100%;
  }

  .lambert .bank-text {
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 16px;
    width: 100%;
  }
  .lambert .open-debt-png {
    width: 100%;
    height: auto;
    max-width: 650px;
  }

  .lambert .stats-no-swears {
    width: 100%;
    height: auto;
    margin-bottom: 0px;
    /* max-width: fit-content; */
  }
  .lambert .poll {
    width: 100%;
    height: auto;
  }
  .lambert .roll {
    width: 100%;
    height: auto;
  }
  .lambert .secret-santa {
    width: 100%;
    height: auto;
    margin-top: 16px;
  }
  .lambert .overlap-wrapper {
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-right: 100px;
  }
  .lambert .overlap {
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .lambert .overlap-group-swear-jar {
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .lambert .overlap-group-2 {
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .lambert .frame-poll-3 {
    align-items: center;
    align-self: center;
    display: flex;
    flex-direction: column-reverse;
    gap: 30px;
    position: relative;
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
  }
  .lambert .frame-swear-3 {
    width: 100%;
    margin-top: 0px;
    height: auto;
  }
  .lambert .frame-poll-header-text {
    width: 100%;
  }
}

@media (max-width: 450px) {
  .lambert .section {
    padding: 30px 30px;
  }
}
@media (max-width: 400px) {
  .lambert .text-wrapper-3 {
    font-size: 20px;
  }
  .lambert .bank-text {
    font-size: 15px;
    width: 100%;
  }
  .lambert .pay-for-your-swears {
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 16px;
    width: 100%;
  }
  .lambert .section {
    padding: 30px 25px;
  }
  .lambert .text-wrapper-12 {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 370px) {
  .lambert .section {
    padding: 30px 20px;
  }
}
@media (max-width: 360px) {
  .lambert .section {
    padding: 30px 15px;
  }
}
@media (max-width: 350px) {
  .lambert .text-wrapper-3 {
    font-size: 18px;
  }
  .lambert .bank-text {
    font-size: 14px;
  }
  .lambert .pay-for-your-swears {
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 16px;
    /* font-size: 15px; */
  }
  .lambert .section {
    padding: 30px 10px;
  }
}

@media (max-width: 340px) {
  .lambert .text-wrapper-11 {
    font-size: 16px;
  }
  .lambert .text-wrapper-12 {
    font-size: 16px;
  }
}
