/* general policy css*/

ul li{
  list-style: disc;
  margin-left: 15px;
  color: #4F4F4F;
  font-family: "Lato", Helvetica;
}

.frame .text-wrapper {
  color: #009dff;
  font-family: "Lato", Helvetica;
  font-weight: 600;
  letter-spacing: 0;
  color: #115F78;
}
.text-wrapper-3{
  color: #4F4F4F;
}

.frame .section {
  width: 700px;
}

.frame .text-wrapper-2 {
  color: #115F78;
  font-family: "Lato", Helvetica;
  font-style: var(--heading-1-font-style);
  font-weight: 700;
}

.frame .p {
  color: #115F78;
  font-family: "Lato", Helvetica;
  font-style: italic;
}

.frame .text-wrapper-3 {
  color: #4F4F4F;
  font-family: "Lato", Helvetica;
  font-style: var(--paragraph-font-style);
  font-weight: var(--paragraph-font-weight);
}

.frame .text-wrapper-4 {
  color: #115F78;
  font-family: "Lato", Helvetica;
  font-size: 16px;
  font-style: italic;
  font-weight: var(--heading-2-font-weight);
}

.frame .div-2 {
  color: #050505;
  font-family: "Lato", Helvetica;
}

.frame .span {
  color: #4F4F4F;
  font-family: "Lato", Helvetica;
  font-style: var(--paragraph-font-style);
  font-weight: var(--paragraph-font-weight);
}

.frame .text-wrapper-5 {
  color: #115f78;
  font-family: "Lato", Helvetica;
  font-style: var(--link-font-style);
  font-weight: var(--link-font-weight);
}

.frame {
  align-items: center;
  background-color: #e3fcff;
  display: inline-flex;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
  padding-bottom: 100px;
  padding-top: 100px;
  padding-left: 20%;
  padding-right: 60%;
  position: relative;
}

.frame .made-with-by {
  color: transparent;
  font-family: "Lato", Helvetica;
  font-weight: 400;
}

.frame .text-wrapper-6 {
  color: #000000;
}

.frame .text-wrapper-7 {
  color: #115f78;
}

.frame .link-support {
  color: #115F78;
  font-family: "Lato", Helvetica;
  font-size: 14px;
  font-weight: 400;
}

@media (max-width: 1600px) {

  .frame {
    align-items: center;
    background-color: #e3fcff;
    display: inline-flex;
    flex-direction: column;
    gap: 40px;
    justify-content: center;
    padding-bottom: 100px;
    padding-top: 100px;
    padding-left: 10%;
    padding-right: 60%;
    position: relative;
  }



  .frame .logo {
    height: 175px;
    position: relative;
    width: 132px;
  }

  .frame .text-wrapper {
    color: #009dff;
    /* font-family: "Lato", Helvetica; */
    font-family: "Lato", Helvetica;
      font-size: 38px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: fit-content;
    color: #115F78;
  }

  .frame .section {
    align-items: flex-start;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    position: relative;
    width: 700px;
  }

  .frame .div {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    position: relative;
    width: 100%;
  }

  .frame .text-wrapper-2 {
    align-self: stretch;
    color: #115F78;
    font-family: "Lato", Helvetica;
    font-size: 18px;
    font-style: var(--heading-1-font-style);
    font-weight: 700;
    letter-spacing: var(--heading-1-letter-spacing);
    line-height: var(--heading-1-line-height);
    margin-top: -1px;
    position: relative;
  }

  .frame .p {
    align-self: stretch;
    color: #115F78;
    font-family: "Lato", Helvetica;
    font-size: 16px;
    font-style: italic;
    font-weight: var(--heading-2-font-weight);
    letter-spacing: var(--heading-2-letter-spacing);
    line-height: var(--heading-2-line-height);
    position: relative;
  }

  .frame .text-wrapper-3 {
    align-self: stretch;
    color: #4F4F4F;
    font-family: "Lato", Helvetica;
    font-size: 14px;
    font-style: var(--paragraph-font-style);
    font-weight: var(--paragraph-font-weight);
    letter-spacing: var(--paragraph-letter-spacing);
    line-height: var(--paragraph-line-height);
    position: relative;
  }

  .frame .text-wrapper-4 {
    align-self: stretch;
    color: #115F78;
    font-family: "Lato", Helvetica;
    font-size: 16px;
    font-style: italic;
    font-weight: var(--heading-2-font-weight);
    letter-spacing: var(--heading-2-letter-spacing);
    line-height: var(--heading-2-line-height);
    margin-top: -1px;
    position: relative;
  }

  .frame .div-2 {
    align-self: stretch;
    color: #050505;
    font-family: "Lato", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 14px;
    position: relative;
  }

  .frame .span {
    color: #4F4F4F;
    font-family: "Lato", Helvetica;
    font-size: 14px;
    font-style: var(--paragraph-font-style);
    font-weight: var(--paragraph-font-weight);
    letter-spacing: var(--paragraph-letter-spacing);
    line-height: var(--paragraph-line-height);
  }

  .frame .text-wrapper-5 {
    color: #115f78;
    font-family: "Lato", Helvetica;
    font-size: var(--link-font-size);
    font-style: var(--link-font-style);
    font-weight: var(--link-font-weight);
    letter-spacing: var(--link-letter-spacing);
    line-height: var(--link-line-height);
    text-decoration: underline;
  }

  .frame {
    align-items: center;
    background-color: #e3fcff;
    display: inline-flex;
    flex-direction: column;
    gap: 40px;
    justify-content: center;
    padding-bottom: 100px;
    padding-top: 100px;
    padding-left: 20%;
    padding-right: 60%;
    position: relative;
  }

    .frame .section {
      height: auto;
    }

  .frame .div-3 {
    align-items: flex-start;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    position: relative;
    width: 960px;
  }

  .frame .made-with-by {
    color: transparent;
    font-family: "Lato", Helvetica;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }

  .frame .text-wrapper-6 {
    color: #000000;
  }

  .frame .text-wrapper-7 {
    color: #115f78;
  }

  .frame .div-align-center {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    position: relative;
  }

  .frame .link-support {
    color: #115F78;
    font-family: "Lato", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
}

@media (min-width: 1600px) {

  .frame {
    align-items: center;
    background-color: #e3fcff;
    display: inline-flex;
    flex-direction: column;
    gap: 40px;
    justify-content: center;
    padding-bottom: 100px;
    padding-top: 100px;
    padding-left: 24%;
    padding-right: 60%;
    position: relative;
  }
  
  
  
  .frame .logo {
    height: 175px;
    position: relative;
    width: 132px;
  }
  
  .frame .text-wrapper {
    color: #115F78;
    /* font-family: "Lato", Helvetica; */
    font-family: "Lato", Helvetica;
    font-size: 38px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: fit-content;
  }
  
  .frame .section {
    align-items: flex-start;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    position: relative;
    width: 700px;
  }
  
  .frame .div {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    position: relative;
    width: 100%;
  }
  
  .frame .text-wrapper-2 {
    align-self: stretch;
    color: #115F78;
    font-family: "Lato", Helvetica;
    font-size: var(--heading-1-font-size);
    font-style: var(--heading-1-font-style);
    font-weight: 700;
    letter-spacing: var(--heading-1-letter-spacing);
    line-height: var(--heading-1-line-height);
    margin-top: -1px;
    position: relative;
  }
  
  .frame .p {
    align-self: stretch;
    color: #115F78;
    font-family: "Lato", Helvetica;
    font-size: 16px;
    font-style: italic;
    font-weight: var(--heading-2-font-weight);
    letter-spacing: var(--heading-2-letter-spacing);
    line-height: var(--heading-2-line-height);
    position: relative;
  }
  
  .frame .text-wrapper-3 {
    align-self: stretch;
    font-family: "Lato", Helvetica;
    font-size: var(--paragraph-font-size);
    font-style: var(--paragraph-font-style);
    font-weight: var(--paragraph-font-weight);
    letter-spacing: var(--paragraph-letter-spacing);
    line-height: var(--paragraph-line-height);
    position: relative;
  }
  
  .frame .text-wrapper-4 {
    align-self: stretch;
    color: #115F78;
    font-family: "Lato", Helvetica;
    font-size: 16px;
    font-style: var(--heading-2-font-style);
    font-weight: var(--heading-2-font-weight);
    letter-spacing: var(--heading-2-letter-spacing);
    line-height: var(--heading-2-line-height);
    margin-top: -1px;
    position: relative;
  }
  
  .frame .div-2 {
    align-self: stretch;
    font-family: "Lato", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 14px;
    position: relative;
  }
  
  .frame .span {
    color: #4F4F4F;
    font-family: "Lato", Helvetica;
    font-size: 14px;
    font-style: var(--paragraph-font-style);
    font-weight: var(--paragraph-font-weight);
    letter-spacing: var(--paragraph-letter-spacing);
    line-height: var(--paragraph-line-height);
  }
  
  .frame .text-wrapper-5 {
    color: #115f78;
    font-family: "Lato", Helvetica;
    font-size: var(--link-font-size);
    font-style: var(--link-font-style);
    font-weight: var(--link-font-weight);
    letter-spacing: var(--link-letter-spacing);
    line-height: var(--link-line-height);
    text-decoration: underline;
  }
  
  .frame .div-3 {
    align-items: flex-start;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    position: relative;
    width: 960px;
  }
  
  .frame .made-with-by {
    color: transparent;
    font-family: "Lato", Helvetica;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }
  
  .frame .text-wrapper-6 {
    color: #000000;
  }
  
  .frame .text-wrapper-7 {
    color: #115f78;
  }
  
  .frame .div-align-center {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    position: relative;
  }
  
  .frame .link-support {
    color: #115F78;
    font-family: "Lato", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  }
  
  @media (max-width: 1250px) {
    .frame {
      padding-left: 17%;
      padding-right: 60%;
      height: auto;
      display: inline-flex;
    }

    .frame .section {
      width: auto;
      height: auto;
      padding: 0% 15%;
    }
    
  }


  @media (max-width: 1200px) {
    .frame {
      padding-left: 13%;
      padding-right: 60%;
      height: auto;
      display: inline-flex;
    }

    .frame .section {
      width: auto;
      height: auto;
      padding: 0% 15%;
    }

    #app .frame .div-3 {
      padding: 0% 10% 0 10%;
      font-size: 8px;
    }
  }

  @media (max-width: 1050px) {
    .frame {
      padding-left: 7%;
      padding-right: 60%;
      height: auto;
      display: inline-flex;
    }

    .frame .section {
      width: auto;
      height: auto;
      padding: 0% 15%;
    }
    
    #app .frame .div-3 {
      padding: 0% 10% 0 10%;
    }
  }


  @media (max-width: 1000px) {
    #app .frame {
      height: auto;
      position: absolute;
      width: 100%;
      padding: 50 0 40 0 ;
      margin: 0;
    }

    #app .frame .text-wrapper {
      width: auto;
      height: auto;
      padding: 0% 15%;
      text-align: center;
    }

    #app .frame .div-3 {
      width: 100%;
      height: auto;
      padding: 0% 10% 0 10%;
      font-size: 8px;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
    }
    
  }
